
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist-sig";

import {
  PDFDocumentProxy,
  PDFPageProxy,
} from "node_modules/pdfjs-dist-sig/types/display/api";
import PdfjsWorker from "worker-loader!pdfjs-dist-sig/es5/build/pdf.worker.js";
import useBreakpoint from "@/lib/compositional-logic/useBreakpoint";

if (typeof window !== "undefined" && "Worker" in window) {
  GlobalWorkerOptions.workerPort = new PdfjsWorker();
}

import { storage, key } from "@/lib/storage";

export default defineComponent({
  name: "PdfViewer",
  props: {
    src: { type: String, default: "" },
    page: { type: Number, default: 1 },
    scaleFitWidth: { type: Boolean, default: false },
    fileId: { type: String, default: "" },
  },
  emits: ["loaded", "pageLoaded", "pageNums", "pageScaled", "pageSized"],
  setup(props, { emit }) {
    var pdf: unknown = null;
    const state = reactive({
      pdf: pdf,
      renderedPage: props.page,
    });

    const canvasW = ref(0);

    const loadingTask = getDocument({
      url: props.src,
      withCredentials: true,
      cMapUrl: "/cmaps/",
      cMapPacked: true,
    });

    onMounted(async () => {
      const pdfFile = await loadingTask.promise;
      state.pdf = pdfFile;
      emit("loaded");
      emit("pageNums", pdfFile.numPages);

      const pdfPage = await pdfFile.getPage(props.page);
      const { pageNum, scale, width, height } = await renderPdf(
        pdfPage,
        props.page,
        props.scaleFitWidth,
        props.fileId
      );
      emit("pageLoaded", pageNum);
      emit("pageScaled", scale);
      emit("pageSized", { width, height });
    });

    watch(
      () => props.page,
      async (page) => {
        const pdfFile = state.pdf as PDFDocumentProxy;
        if (pdfFile == null) return;
        state.renderedPage = page;

        const pdfPage = await pdfFile.getPage(page);
        const { pageNum, scale } = await renderPdf(
          pdfPage,
          props.page,
          props.scaleFitWidth,
          props.fileId
        );
        emit("pageLoaded", pageNum);
        emit("pageScaled", scale);
      }
    );

    watch(
      () => props.src,
      async (src) => {
        const loadingTask = getDocument(src);

        const pdfFile = await loadingTask.promise;
        state.pdf = pdfFile;
        state.renderedPage = 1;
        emit("loaded");
        emit("pageNums", pdfFile.numPages);

        const pdfPage = await pdfFile.getPage(state.renderedPage);
        const { pageNum, scale } = await renderPdf(
          pdfPage,
          state.renderedPage,
          props.scaleFitWidth,
          props.fileId
        );
        emit("pageLoaded", pageNum);
        emit("pageScaled", scale);
      }
    );

    useBreakpoint(
      async () => {
        const pdfFile = await loadingTask.promise;
        state.pdf = pdfFile;

        const pdfPage = await pdfFile.getPage(props.page);
        const { width, height, scale } = await renderPdf(
          pdfPage,
          props.page,
          props.scaleFitWidth,
          props.fileId,
          true
        );
        emit("pageSized", { width, height });
        emit("pageScaled", scale);
      },
      { debounceTime: 300 }
    );

    const renderPdf = (
      page: PDFPageProxy,
      pageNum: number,
      scaleFitWidth: boolean,
      fileId: string,
      onlyRenderOnViewportChange = false
    ): Promise<{
      pageNum: number;
      scale: number;
      width: number;
      height: number;
    }> => {
      const canvasId = "pdf-canvas" + fileId;
      const overlayId = "canvas-overlay" + fileId;
      const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
      const overlay = document.getElementById(overlayId) as HTMLDivElement;
      const context = canvas.getContext("2d");
      canvas.style.width = "100%";
      canvas.style.height = "100%";
      overlay.style.width = "100%";
      overlay.style.height = "100%";

      const defaultViewport = page.getViewport({ scale: 1 });

      var scale = 1;

      if (scaleFitWidth) {
        scale = canvas.clientWidth / defaultViewport.width;
      } else {
        const scaleWidth = canvas.clientWidth / defaultViewport.width;
        const scaleHeight = canvas.clientHeight / defaultViewport.height;
        scale = scaleWidth < scaleHeight ? scaleWidth : scaleHeight;
      }

      const viewport = page.getViewport({ scale });

      if (onlyRenderOnViewportChange && viewport.width == canvasW.value)
        return Promise.resolve().then(() => ({
          pageNum,
          scale,
          width: defaultViewport.width,
          height: defaultViewport.height,
        }));
      canvasW.value = viewport.width;

      canvas.height = Math.floor(viewport.height);
      canvas.width = Math.floor(viewport.width);

      canvas.style.width = Math.floor(viewport.width) + "px";
      canvas.style.height = Math.floor(viewport.height) + "px";
      overlay.style.width = Math.floor(viewport.width) + "px";
      overlay.style.height = Math.floor(viewport.height) + "px";

      var renderContext = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        canvasContext: context!,
        viewport: viewport,
      };
      return new Promise<void>((resolve, reject) => {
        requestAnimationFrame(() => {
          page
            .render(renderContext)
            .promise.then(() => resolve())
            .catch(() => reject());
        });
      })
        .then(() => ({
          pageNum,
          scale,
          width: defaultViewport.width,
          height: defaultViewport.height,
        }))
        .catch(() => ({
          pageNum,
          scale,
          width: defaultViewport.width,
          height: defaultViewport.height,
        }));
    };

    return {
      ...toRefs(state),
    };
  },
});
